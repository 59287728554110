'use client';
import { type ReactNode } from 'react';
import Link from 'next/link';
import { usePath } from '@/hooks/usePath';
import { classes, styles } from './utils';

export type SidebarButtonProps = {
  disabled?: boolean;
  collapsed?: boolean;
  href: string;
  label: string;
  icon?: ReactNode;
  color?: string;
  bgColor?: string;
};

export default function SidebarButton({
  href,
  label,
  icon,
  collapsed,
  disabled,
  color,
  bgColor,
}: SidebarButtonProps) {
  const { pathname, searchParams } = usePath();
  const isActive = pathname.includes(href);
  let linkClasses = isActive ? classes.active : classes.inactive;
  const content = (
    <>
      {icon && (
        <span
          style={styles.icon}
          className={`${classes.icon} ${collapsed ? '' : classes.iconOpen}`}>
          {icon}
        </span>
      )}
      <span style={styles.label(collapsed)}>{label}</span>
    </>
  );
  return disabled ? (
    <div className={`${classes.button} ${classes.disabled}`}>{content}</div>
  ) : (
    <Link
      href={`${href}?${searchParams.toString()}`}
      style={isActive ? styles.active(color, bgColor) : {}}
      className={`${classes.button} ${linkClasses}`}>
      {content}
    </Link>
  );
}
