export const classes = {
  menu: 'mt-2 px-4 pt-4 pb-0 w-[425px] overflow-hidden',
  buttonsWrapper: 'flex justify-center items-center',
  optionsWrapper: 'flex justify-center items-center ml-2',
  list: 'overflow-y-auto min-h-0 max-h-[350px]',
  icon: 'cursor-pointer text-grey-6 hover:text-grey-7',
  header: 'w-full flex justify-between px-2 mb-3',
  button:
    'text-grey-7 font-normal border-b border-white hover:text-purple-2 hover:border-purple-2 cursor-pointer select-none transition-colors px-1',
  emptyMessage: 'text-grey-7 text-center text-lg py-8',
};
