import { useAppState } from '@/providers/AppStateProvider';
import {
  ACCOUNTS,
  LOGIN,
  NAVIGATION,
  SHORTCUTS,
  SUMMARY_REPORT_EXPORT,
} from '@/constants/dialogs';
import { SHORTCUT_KEYS } from '@/constants/shortcutKeys';
import { NAVIGATION_KEYS } from '@/constants/navigationKeys';
import AccountsDialog from '@/dialogs/AccountsDialog';
import LoginDialog from '@/dialogs/LoginDialog';
import ShortcutsDialog from '@/dialogs/ShortcutsDialog';
import NavigationDialog from '@/dialogs/NavigationDialog';
import SummaryReportDialog from '@/dialogs/SummaryReportDialog';

export type DialogsManagerProps = {
  onSelectShortcut: (shortcut: keyof typeof SHORTCUT_KEYS) => void;
  onSelectNavigation: (shortcut: keyof typeof NAVIGATION_KEYS) => void;
};

export default function DialogsManager({
  onSelectShortcut,
  onSelectNavigation,
}: DialogsManagerProps) {
  const { activeDialogs, onCloseDialog } = useAppState();

  return (
    <>
      {activeDialogs[SHORTCUTS] && (
        <ShortcutsDialog
          onSelect={onSelectShortcut}
          onClose={() => onCloseDialog(SHORTCUTS)}
        />
      )}
      {activeDialogs[NAVIGATION] && (
        <NavigationDialog
          onSelect={onSelectNavigation}
          onClose={() => onCloseDialog(NAVIGATION)}
        />
      )}
      {activeDialogs[SUMMARY_REPORT_EXPORT] && (
        <SummaryReportDialog
          onClose={() => onCloseDialog(SUMMARY_REPORT_EXPORT)}
        />
      )}
      {activeDialogs[ACCOUNTS] && (
        <AccountsDialog onClose={() => onCloseDialog(ACCOUNTS)} />
      )}
      {activeDialogs[LOGIN] && (
        <LoginDialog onClose={() => onCloseDialog(LOGIN)} />
      )}
    </>
  );
}
