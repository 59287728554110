import { useRef } from 'react';
import { type Notification } from '@witmetrics/api-client';
import Menu, { type MenuProps } from '@/components/Menu';
import Title from '@/components/Title';
import OptionsIcon from '@/icons/OptionsIcon';
import NotificationsOptionsMenu from '@/components/NotificationsOptionsMenu';
import NotificationsList from '@/components/NotificationsList';
import { useToggle } from '@/hooks/useToggle';
import { usePath } from '@/hooks/usePath';
import { classes } from './utils';

export interface NotificationsMenuProps extends MenuProps {
  notifications: Notification[];
}

export default function NotificationsMenu({
  open,
  anchorTo,
  anchorOrigin,
  transformOrigin,
  notifications,
  onClose,
}: NotificationsMenuProps) {
  const menuToggleRef = useRef<HTMLDivElement>(null);
  const { navigate } = usePath();
  const [isOptionsMenuOpen, toggleOptionsMenu] = useToggle(false);

  const handleViewAll = () => {
    navigate('/dashboard/notifications/');
    onClose();
  };

  return (
    <>
      <Menu
        open={open}
        className={classes.menu}
        anchorTo={anchorTo}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        onClose={onClose}>
        <div className={classes.header}>
          <Title size="sm">Notifications</Title>
          <div className={classes.buttonsWrapper}>
            <button className={classes.button} onClick={handleViewAll}>
              View all
            </button>
            <div className={classes.optionsWrapper} ref={menuToggleRef}>
              <OptionsIcon
                className={classes.icon}
                onClick={() => toggleOptionsMenu()}
              />
            </div>
          </div>
        </div>
        {notifications.length > 0 ? (
          <NotificationsList
            className={classes.list}
            notifications={notifications}
            onOpen={() => onClose()}
          />
        ) : (
          <div className={classes.emptyMessage}>No notifications</div>
        )}
      </Menu>
      {isOptionsMenuOpen && (
        <NotificationsOptionsMenu
          open={isOptionsMenuOpen}
          anchorTo={menuToggleRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => toggleOptionsMenu()}
        />
      )}
    </>
  );
}
