import { forwardRef } from 'react';
import { type Notification } from '@witmetrics/api-client';
import Snackbar from '@mui/material/Snackbar';
import Slide, { type SlideProps } from '@mui/material/Slide';
import { NotificationsListItem } from '@/components/NotificationsList';
import { classes, styles } from './utils';

export type NotificationToasterProps = {
  notification: Notification;
  onClose: () => void;
};

const Transition = forwardRef<any, SlideProps>((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function NotificationToaster({
  notification,
  onClose,
}: NotificationToasterProps) {
  return (
    <Snackbar
      open
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      ClickAwayListenerProps={{ mouseEvent: false }}
      TransitionComponent={Transition}
      onClose={onClose}>
      <div style={styles.wrapper} className={classes.wrapper}>
        <NotificationsListItem
          className={classes.listItem}
          notification={notification}
          onOpen={onClose}
          onDelete={onClose}
        />
      </div>
    </Snackbar>
  );
}
