export const classes = {
  listItemButton: 'py-6 px-8 mx-3 my-1 rounded-lg bg-white hover:bg-grey-2',
  shortcut: 'text-grey-5 font-light text-2xl pr-12',
  name: 'text-grey-7 font-normal text-2xl',
  paper: 'fixed top-8 rounded-2xl',
  listWrapper: 'flex flex-col w-[90vw] max-w-[400px] min-w-[200px] min-h-0',
  list: 'flex-0 max-h-[80vh] overflow-auto',
};

export const NAVIGATION_OPTIONS = [
  { name: 'Dashboard', shortcut: '/D' },
  { name: 'Reports', shortcut: '/R' },
  { name: 'Segments', shortcut: '/S' },
] as const;
