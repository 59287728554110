export const classes = {
  wrapper: 'relative top-4 w-[425px] bg-white rounded-xl',
  listItem: 'mb-0 px-4',
};

export const styles = {
  wrapper: {
    boxShadow: '0 8px 20px 0 hsla(0,0%,0%,0.3)',
  },
};
