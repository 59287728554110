import { API } from '@/api';
import { buildColumnFilter } from '@/utils/filters';
import { COMPARISON_OPERATORS, IsReadFilter } from '@witmetrics/api-client';

export const classes = {
  clearAll: 'text-red-2',
};

export const CLEAR_READ_FILTER = buildColumnFilter(
  'isRead',
  COMPARISON_OPERATORS.IS.value,
  true
) as IsReadFilter;

export function readAllNotifications() {
  return API.Notifications.updateAllNotifications({ isRead: true });
}

export function clearReadNotifications() {
  return API.Notifications.removeAllNotifications(CLEAR_READ_FILTER);
}

export function clearAllNotifications() {
  return API.Notifications.removeAllNotifications();
}
