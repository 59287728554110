import { cloneElement, ReactElement } from 'react';
import DialogActions from '../DialogActions';
import Button, { ButtonProps } from '@/components/Button';
import CheckIcon from '@/icons/CheckIcon';
import { classes } from './utils';

export type DefaultDialogActionsProps = {
  confirmLabel?: string;
  pendingLabel?: string;
  cancelLabel?: string;
  confirmEnabled?: boolean;
  pending?: boolean;
  disabled?: boolean;
  onConfirm: ButtonProps['onClick'];
  onCancel: () => void;
  confirmIcon?: ReactElement;
  className?: string;
};

export default function DefaultDialogActions({
  confirmLabel = 'Confirm',
  pendingLabel = 'Sending...',
  cancelLabel = 'Cancel',
  pending,
  disabled,
  onConfirm,
  onCancel,
  confirmIcon,
  className = '',
  ...props
}: DefaultDialogActionsProps) {
  const renderIcon = () => {
    return confirmIcon ? (
      cloneElement(confirmIcon, { className: classes.icon })
    ) : (
      <CheckIcon className={classes.icon} />
    );
  };

  return (
    <DialogActions className={className} {...props}>
      <Button
        variant="text"
        disabled={pending}
        textClasses={classes.button}
        // tabIndex={-1}
        onClick={onCancel}>
        {cancelLabel}
      </Button>
      <Button disabled={disabled || pending} onClick={onConfirm}>
        {pending ? null : renderIcon()}
        {pending ? pendingLabel : confirmLabel}
      </Button>
    </DialogActions>
  );
}
