import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { useToggle } from '@/hooks/useToggle';
import { useAppState } from '@/providers/AppStateProvider';
import {
  useActiveAccount,
  useDateFormatPreference,
  useWeekStartsOnPreference,
} from '@/store/useStore';
import { useReports } from '@/providers/ReportsProvider';
import DateRangeInput from '@/components/DateRangeInput';
import type { DateRange } from '@/types/dates';
import { isTimeframeChanged } from '@/utils/time';
import { buildFileName, classes, fetchSummaryReport } from './utils';

export type SummaryReportDialogProps = {
  onClose: () => void;
};

export default function SummaryReportDialog({
  onClose,
}: SummaryReportDialogProps) {
  const activeAccount = useActiveAccount();
  const dateFormat = useDateFormatPreference();
  const weekStartsOn = useWeekStartsOnPreference();
  const { onApiError } = useAppState();
  const { timeframe, onUpdateTimeframe } = useReports();
  const [isExporting, toggleExporting] = useToggle(false);

  const handleClose = () => {
    if (!isExporting) onClose();
  };

  const handleUpdate = (update: DateRange) => {
    if (isTimeframeChanged(timeframe, update)) {
      onUpdateTimeframe(update);
    }
  };

  const handleExport = async () => {
    try {
      toggleExporting(true);
      const reportExport = await fetchSummaryReport(
        activeAccount!.accountID,
        timeframe
      );
      const url = window.URL.createObjectURL(reportExport);
      const a = document.createElement('a');
      a.href = url;
      a.download = buildFileName();
      a.click();
      toggleExporting(false);
    } catch (err) {
      onApiError(err, 'Error downloading report');
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={handleClose}>
      <DialogTitle title="Export Summary Report" onClose={handleClose} />
      <DialogBody className={classes.body}>
        <p className={classes.instructions}>
          Please select a date range for your export.
        </p>
        <DateRangeInput
          disablePast={false}
          className={classes.dateRangeInput}
          dateFormat={dateFormat}
          weekStartsOn={weekStartsOn}
          value={timeframe}
          variant="text"
          name="timeframe"
          label=""
          onApply={(update) => handleUpdate(update.timeframe)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isExporting}
        pending={isExporting}
        confirmLabel="Export"
        pendingLabel="Exporting..."
        onCancel={handleClose}
        onConfirm={handleExport}
      />
    </Dialog>
  );
}
