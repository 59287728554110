import {
  getAccountsURL,
  getCRMURL,
  getInsightsURL,
  getPerspectiveURL,
  getUnisonURL,
} from '@/api/getURL';

export const classes = {
  header:
    'relative h-[4rem] px-4 flex items-center justify-between bg-white border-b border-grey-4 shadow-header',
  buttonsWrapper: 'flex items-center',
  icon: 'text-grey-6 mr-3',
  testingWrapper: 'text-orange-2 border border-orange-1 py-1 px-4 rounded-md',
  testingLink: 'ml-2 underline cursor-pointer font-medium',
};

export const HEADER_HEIGHT = '4rem';

export const PLATFORMS = {
  CRM: `${getCRMURL()}/dashboard/overview/`,
  INSIGHTS: `${getInsightsURL()}/dashboard/overview/`,
  UNISON: `${getUnisonURL()}/dashboard/overview/`,
  PERSPECTIVE: getPerspectiveURL(),
  ACCOUNTS: getAccountsURL(),
};
