import { API } from '@/api';
import { type Notification } from '@witmetrics/api-client';
import { buildColumnFilter } from '@/utils/filters';
import { COMPARISON_OPERATORS } from '@/constants/comparisonOperators';
import { sortByCreatedAt } from '@/utils/arrays';

export const styles = {
  unread: {
    animationName: 'ring-notification-bell',
    animationDuration: '1.2s',
    animationTimingFunction: 'ease-in-out',
  },
};

export const PAGE_SIZE = 20;

const isReadFilter = buildColumnFilter(
  'isRead',
  COMPARISON_OPERATORS.IS.value,
  false
);

export function sortNotifications(notifications: Notification[]) {
  return sortByCreatedAt(notifications).slice(0, PAGE_SIZE);
}

export async function fetchNotifications() {
  // One request to get all notifications, one just to get the count of unread notifications
  const [allNotifications, unreadNotifications] = await Promise.all([
    API.Notifications.fetchNotifications({
      filters: [],
      pagination: { page: 1, pageSize: PAGE_SIZE },
    }),
    API.Notifications.fetchNotifications({
      filters: [isReadFilter],
      pagination: { page: 1, pageSize: 1 },
    }),
  ]);
  return {
    notifications: allNotifications.results,
    unreadCount: unreadNotifications.pagination.rowCount,
  };
}
