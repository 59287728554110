import { useDispatch } from '@/store/useStore';
import {
  deleteAllNotifications as dispatchDeleteAllNotifications,
  updateAllNotifications as dispatchUpdateAllNotifications,
} from '@/store/slices/notificationsSlice';
import { setUnreadNotificationsCount as dispatchSetUnreadNotificationsCount } from '@/store/slices/unreadNotificationsCountSlice';
import Menu, { MenuItem, type MenuProps } from '@/components/Menu';
import { useAppState } from '@/providers/AppStateProvider';
import {
  classes,
  CLEAR_READ_FILTER,
  clearAllNotifications,
  clearReadNotifications,
  readAllNotifications,
} from './utils';

export interface NotificationsOptionsMenuProps extends MenuProps {}

export default function NotificationsOptionsMenu({
  open,
  anchorTo,
  anchorOrigin,
  transformOrigin,
  onClose,
}: NotificationsOptionsMenuProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();

  const handleReadAll = () => {
    try {
      readAllNotifications();
      dispatch(dispatchUpdateAllNotifications({ isRead: true }));
      dispatch(dispatchSetUnreadNotificationsCount(0));
      onClose();
    } catch (err) {
      onApiError(err, 'Error updating notifications');
    }
  };

  const handleClearRead = () => {
    try {
      clearReadNotifications();
      dispatch(dispatchDeleteAllNotifications({ filter: CLEAR_READ_FILTER }));
      onClose();
    } catch (err) {
      onApiError(err, 'Error clearing notifications');
    }
  };

  const handleClearAll = () => {
    try {
      clearAllNotifications();
      dispatch(dispatchDeleteAllNotifications({ filter: undefined }));
      dispatch(dispatchSetUnreadNotificationsCount(0));
      onClose();
    } catch (err) {
      onApiError(err, 'Error clearing notifications');
    }
  };

  return (
    <Menu
      open={open}
      anchorTo={anchorTo}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onClose}>
      <MenuItem label={'Mark all as read'} onClick={handleReadAll} />
      <MenuItem label={'Clear read'} onClick={handleClearRead} />
      <MenuItem
        className={classes.clearAll}
        label={'Clear all'}
        onClick={handleClearAll}
      />
    </Menu>
  );
}
