export const classes = {
  sidebar:
    'h-full shadow-sidebar px-5 py-6 bg-white overflow-auto flex flex-col justify-between',
  open: 'w-[15rem]',
  collapsed: 'w-[6.25rem]',
  iconWrapper: 'flex justify-start px-4',
  menuIcon: 'text-[2rem] cursor-pointer text-grey-6',
  icon: 'text-[1.75rem]',
};

export const styles = {
  sidebar: { transition: 'width 0.15s' },
  menuIcon: (isCollapsed?: boolean) => ({
    transform: `rotateY(${isCollapsed ? '180deg' : '0deg'})`,
    transition: 'transform 0.15s',
  }),
};
