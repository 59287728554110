import { API } from '@/api';
import { COMPARISON_OPERATORS } from '@/constants/comparisonOperators';
import { DateRange } from '@/types/dates';
import { format } from 'date-fns';

const { BETWEEN } = COMPARISON_OPERATORS;

export const classes = {
  dialog:
    'w-[98vw] max-w-[600px] min-w-[200px] min-h-[250px] max-h-[450px] h-[90vh] flex flex-col',
  body: 'grow pt-0 min-h-0',
  instructions: 'text-base text-grey-8 mb-8',
  dateRangeInput: 'w-full',
};

export async function fetchSummaryReport(
  practiceID: number,
  timeframe: DateRange
) {
  return API.Reports.fetchSummaryReportExport(practiceID, {
    startDate: timeframe[0],
    endDate: timeframe[1],
  });
}

export function buildFileName() {
  const dateStr = format(new Date(), 'yyyyMMdd-HHmmss');
  return `witmetrics-summary_${dateStr}.xlsx`;
}
