import List from '@mui/material/List';
import { type Notification } from '@witmetrics/api-client';
import { API } from '@/api';
import { useDispatch } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { deleteNotification as dispatchDeleteNotification } from '@/store/slices/notificationsSlice';
import NotificationsListItem from './NotificationsListItem';

export { NotificationsListItem };

export type NotificationsListProps = {
  isFetchingData?: boolean;
  className?: string;
  itemClassName?: string;
  notifications: Notification[];
  onOpen?: (notification: Notification) => void;
  onDelete?: (notificationID: number) => void;
};

export default function NotificationsList({
  isFetchingData,
  className,
  itemClassName,
  notifications,
  onOpen,
  onDelete,
}: NotificationsListProps) {
  // TODO: isFetchingData
  const dispatch = useDispatch();
  const { onApiError } = useAppState();

  const handleDelete = (notificationID: number) => {
    try {
      // No real need to wait for the API response
      API.Notifications.removeNotification(notificationID);
      dispatch(dispatchDeleteNotification({ notificationID }));
      if (onDelete) onDelete(notificationID);
    } catch (err) {
      onApiError(err, 'Error deleting notification');
    }
  };

  return (
    <List className={className}>
      {notifications.map((n) => (
        <NotificationsListItem
          key={n.id}
          className={itemClassName}
          notification={n}
          onOpen={onOpen}
          onDelete={handleDelete}
        />
      ))}
    </List>
  );
}
