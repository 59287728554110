export const classes = {
  wrapper:
    'w-full cursor-pointer hover:bg-grey-2 flex justify-between py-3 pl-4 pr-2 rounded-xl mb-2',
  contentWrapper: 'grow',
  description: 'text-base text-grey-8',
  timestamp: 'text-sm text-grey-6 mt-1',
  optionsWrapper: 'flex items-center justify-center pl-2 w-10',
  status: 'read-status h-4 w-4 rounded-full bg-purple-2',
  closeIcon: 'close-icon text-grey-6 hover:text-grey-7 p-0 m-0',
};

export const styles = {
  wrapper: {
    '&:hover .read-status': {
      display: 'none',
    },
    '&:hover .close-icon': {
      display: 'inline-block',
    },
    '& .close-icon': {
      display: 'none',
    },
  },
};
