import ReportsIcon from '@/icons/ReportsIcon';
import SegmentsIcon from '@/icons/SegmentsIcon';
import HomeIcon from '@/icons/HomeIcon';
import Sidebar, { SidebarButton } from '@/components/Sidebar';
import { usePracticePreferences } from '@/store/useStore';
import { useAppState } from '@/providers/AppStateProvider';
import { classes } from './utils';

export default function AppSidebar() {
  const { isSidebarCollapsed, onToggleSidebarCollapsed } = useAppState();
  const preferences = usePracticePreferences();

  const sharedProps = {
    collapsed: isSidebarCollapsed,
    color: preferences?.buttonTextColor?.value,
    bgColor: preferences?.buttonBgColor?.value,
  };

  return (
    <Sidebar
      collapsible
      isCollapsed={isSidebarCollapsed}
      onToggleCollapsed={(collapsed) => onToggleSidebarCollapsed(collapsed)}>
      <SidebarButton
        {...sharedProps}
        href="/dashboard/overview/"
        label="Overview"
        icon={<HomeIcon className={classes.icon} />}
      />
      <SidebarButton
        {...sharedProps}
        href="/dashboard/reports/"
        label="Reports"
        icon={<ReportsIcon className={classes.icon} />}
      />
      <SidebarButton
        {...sharedProps}
        href="/dashboard/segments/"
        label="Segments"
        icon={<SegmentsIcon className={classes.icon} />}
      />
    </Sidebar>
  );
}
