import { MouseEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';
import { type Notification } from '@witmetrics/api-client';
import { renderRelativeDate } from '@/utils/time';
import { useDateFormatPreference } from '@/store/useStore';
import ListItem from '@mui/material/ListItem';
import CloseIcon from '@/icons/CloseIcon';
import { useAppState } from '@/providers/AppStateProvider';
import { classes, styles } from './utils';

export type NotificationsListItemProps = {
  className?: string;
  notification: Notification;
  onOpen?: (notification: Notification) => void;
  onDelete: (notificationID: number) => void;
};

export default function NotificationsListItem({
  className,
  notification,
  onOpen,
  onDelete,
}: NotificationsListItemProps) {
  const { onOpenNotification } = useAppState();
  const dateFormat = useDateFormatPreference();

  const handleOpen: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onOpenNotification(notification);
    if (onOpen) onOpen(notification);
  };

  const handleDelete: MouseEventHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(notification.id);
  };

  return (
    <ListItem
      disablePadding
      sx={styles.wrapper}
      className={twMerge(classes.wrapper, className)}
      onClick={handleOpen}>
      <div className={classes.contentWrapper}>
        <div className={classes.description}>
          {notification.content.description}
        </div>
        <div className={classes.timestamp}>
          {renderRelativeDate(notification.createdAt, {
            todayAsTime: true,
            dateFormat,
          })}
        </div>
      </div>
      <div className={classes.optionsWrapper}>
        {!notification.isRead && <div className={classes.status} />}
        <CloseIcon className={classes.closeIcon} onClick={handleDelete} />
      </div>
    </ListItem>
  );
}
