import type { ColumnFilter, UndecoratedUser } from '@witmetrics/api-client';
import { FILTER_TYPES } from '@/constants/filterTypes';
import { renderUserName } from './strings';

export type UserOption = Pick<UndecoratedUser, 'id' | 'firstname' | 'lastname'>;

export function buildColumnFilter<T>(
  column: string,
  comparisonOperator: ColumnFilter['comparisonOperator'],
  value: T
) {
  return {
    column,
    comparisonOperator,
    value,
    filterType: FILTER_TYPES.COLUMN_VALUE,
  };
}

export function buildUserOption({ id, firstname, lastname }: UserOption) {
  return {
    value: id,
    label: renderUserName({ firstname, lastname }),
  };
}
