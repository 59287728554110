import { type CSSProperties, type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import SidebarButton from './SidebarButton';
import MenuIcon from '@/icons/MenuIcon';
import { classes, styles } from './utils';

export { SidebarButton };

export type SidebarProps = {
  className?: string;
  style?: CSSProperties;
  collapsible?: boolean;
  isCollapsed?: boolean;
  onToggleCollapsed: (collapsed: boolean) => void;
  children: ReactNode;
};

export default function Sidebar({
  collapsible,
  isCollapsed,
  className = '',
  style = {},
  children,
  onToggleCollapsed,
}: SidebarProps) {
  return (
    <div
      style={{ ...styles.sidebar, ...style }}
      className={twMerge(
        classes.sidebar,
        collapsible && isCollapsed ? classes.collapsed : classes.open,
        className
      )}>
      <div>{children}</div>
      {collapsible && (
        <div className={classes.iconWrapper}>
          <MenuIcon
            className={classes.menuIcon}
            style={styles.menuIcon(isCollapsed)}
            onClick={() => onToggleCollapsed(!isCollapsed)}
          />
        </div>
      )}
    </div>
  );
}
